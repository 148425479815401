<template>
  <div class="profile-body" v-if="
    $oidc.isAuthenticated &&
    $oidc.userProfile &&
    $oidc.userProfile.roles &&
    $oidc.userProfile.roles.length > 0
  ">
    <Loader v-if="showLoader" />
    <Breadcrumb />
    <div class="main-con">
      <div class="mat-info-con">
        <div class="plant-card-info">
          <div class="text-al-cen">
            <b-img v-if="
              myDetails.profilePicUrl != null &&
              myDetails.profilePicUrl != '' &&
              myDetails.profilePicUrl != undefined
            " :src="myDetails.profilePicUrl" rounded="circle" alt="" width="100" height="100" class="pro-img-border"
              @error="setAltImg()"></b-img>
            <div class="no-img-my" v-else>
              <span class="profile-sh">{{
                myDetails.userName | shortName
              }}</span>
            </div>
            <div class="d-flex" style="padding-top: 1rem">
              <div class="pro-con">
                <input type="file" title=" " ref="myBtn" class="upload-file"
                  @change="(e) => onChangeListener(e.target)" />
                <b-button class="asmt-bt button-sm upload-btn" pill variant="outline-primary"
                  @click="buttonClick()">Upload Picture
                </b-button>
              </div>
              <div class="pro-con" style="padding-left: 105px">
                <b-button class="asmt-bt button-sm remove-btn" pill variant="outline-primary"
                  @click="removeBtnFunc()">Remove
                </b-button>
              </div>
            </div>

            <div class="profName" v-if="myDetails.userName">
              {{ myDetails.userName }}
            </div>
            <div class="d-flex" style="padding-top: 10px">
              <div class="role-details" :class="{
                'role-details-sep': index != myDetails.roleDetails.length - 1,
              }" v-for="(roleDetails, index) in myDetails.roleDetails" :key="roleDetails.userRoleId">

                <div class="profDesignation">
                  <div>{{ roleDetails.userRole }}</div>
                  <div v-if="
                    roleDetails.userRole != roles.GLOBALPILLARLEADERNAME &&
                    roleDetails.userRole != roles.CORPORATENAME
                  ">
                    {{ roleDetails.regionName }}
                  </div>
                  <div v-if="
                    roleDetails.userRole == roles.USERNAMEROLE ||
                    roleDetails.userRole == roles.PLANTSUBPILLARLEADERNAME ||
                    roleDetails.userRole == roles.PLANTPILLARLEADERNAME ||
                    roleDetails.userRole == roles.PLANTPOLEADERNAME ||
                    roleDetails.userRole == roles.PLANTMANAGERNAME
                  ">
                    <span v-if="roleDetails.plantName">{{
                      roleDetails.plantName
                    }}</span>
                  </div>
                </div>

                <div v-if="
                  roles.CORPORATENAME != roleDetails.userRole &&
                  roles.REGIONALPOLEADERNAME != roleDetails.userRole &&
                  roles.PLANTMANAGERNAME != roleDetails.userRole &&
                  roles.PLANTPOLEADERNAME != roleDetails.userRole
                ">
                  <span class="pillar" v-if="roleDetails.pillarCode">{{
                    roleDetails.pillarCode
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="plant-card-info2">
          <div class="row">
            <div class="col-5">
              <label for="email" class="form-label page-heading-dropdown lable-text normal-text">
                Email ID
              </label>
              <b-form-input id="email" class="page-content-regular input-fields email-fd" type="text" autocomplete="off"
                v-model="emailId" readonly></b-form-input>

            </div>
            <div class="col-2">
              <label for="observedCondition" class="form-label page-heading-dropdown lable-text normal-text">
                Country Code
              </label>
              <b-form-input id="countryCode" class="page-content-regular input-fields contact" v-model="countryCode"
                autocomplete="off" step="1" maxlength="5" @keydown="filterKey"
                @keypress.native="isNumber($event)"></b-form-input>

            </div>
            <div class="col-5">
              <label for="observedCondition" class="form-label page-heading-dropdown lable-text normal-text">Contact
                Number</label>
              <b-form-input class="page-content-regular input-fields contact" id="contactno" v-model="contactno"
                step="1" minlength="10" maxlength="10" @keydown="filterKey" @keypress.native="isNumberC($event)">
              </b-form-input>
            </div>
          </div>
          <div class="add-action-footer-info">
            <b-button v-b-modal.targetSetTargetYearModal class="primar-btn discard-btn" size="lg"
              @click="backToProfile()">
              Discard
            </b-button>
            <b-button v-b-modal.messagePopup pill class="primar-btn submit-btn" size="lg" @click="saveDetails()">
              Save
            </b-button>

          </div>
        </div>
      </div>
    </div>
    <MessagePopup class="alert" :message="errorMsg" :show="showPopup" :routToBack="true" :headText="headText"
      :reqRaise="reqRaise" />
  </div>
</template>
<script>
import "../../common/css/CommonStyle.css";
import "../../common/css/font-categories.css";
import Breadcrumb from "../../components/common/Breadcrumb.vue";
import Role from "../../common/constants/roles";
import Api from "../../common/constants/api";
import ApiCalls from "../../common/api/apiCalls";
import Loader from "../../components/common/Loader.vue";
import MessagePopup from "@/components/common/MessagePopup.vue";
import Titles from "../../common/constants/titles";

export default {
  name: "EditMyProfile",
  data() {
    return {
      maxLength: 14,
      roles: Role,
      showLoader: true,
      selectType: 1,
      getProfileDetails: false,
      myDetails: [],
      documentDetailsArr: [],
      timeStamp: "",
      profilePicUrl: "",
      address: "",
      emailId: "",
      contactno: "",
      countryCode: "",
      phoneNo: "",
      errorMsg: "",
      reqRaise: "",
      showPopup: false,
      headText: "",
      titles: Titles,
      titlesSelect: [],
      selectedTitle: "",
    };
  },

  components: {
    Breadcrumb,
    Loader,
    MessagePopup,
  },

  beforeCreate() {
    this.$store.dispatch("selectedMenu", {
      main: 0,
      subMenu: 2,
    });
    this.$store.dispatch("breadcrumb", {
      title: "Edit My Profile",
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        { name: "My Profile", primary: false },
      ],
    });
  },
  computed: {},
  created() {
    // titlesSelect
    for (const title in this.titles) {
      if (Object.hasOwnProperty.call(this.titles, title)) {
        const element = this.titles[title];
        this.titlesSelect.push({ value: title.toLowerCase(), label: element });
      }
    }
    this.showLoader = false;
    this.getListOfUsers();
  },
  methods: {
    setAltImg() {
      this.myDetails.profilePicUrl = "";
    },
    removeBtnFunc() {
      this.documentDetailsArr = [];
      this.profilePicUrl = "";
      this.myDetails.profilePicUrl = "";
    },
    handler(evt) {
      for (let i = 1; i < this.countryCode.length; i++) {
        if (this.countryCode[i + 1] == 0) {
          evt.preventDefault();
        }
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      }
      return true;
    },
    isNumberC(evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      }
      return true;
    },
    filterKey(e) {
      const key = e.key;
      if (key === ".") return e.preventDefault();
      if (key === "e") return e.preventDefault();
      if (
        key === "+" ||
        key === "-" ||
        key === "*" ||
        key === "%" ||
        key === "#" ||
        key === "&" ||
        key === "^" ||
        key === " "
      )
        return e.preventDefault();

    },

    // This can also prevent copy + paste invalid character
    filterInput(e) {
      e.target.value = e.target.value.replace(/[^0-9]+/g, "");
    },
    // nameKeydown(e) {
    // if (/^\W$/.test(e.key)) {
    //   e.preventDefault();
    // }
    // },
    // formatContact(e) {
    //   return String(e).substr(0, 10);
    //   // if(this.contactno.length>10){
    //   //   e.preventDefault();
    //   // }
    // },
    // formatCountry(e) {
    //   return String(e).substr(0, 5);
    //   // if(this.contactno.length>10){
    //   //   e.preventDefault();
    //   // }
    // },
    buttonClick() {
      this.selectType = 2;
      if (this.selectType == 2) {
        this.$refs.myBtn.click();
        this.selectType = 1;
      }
    },
    backToProfile() {
      this.$router.push("/profile");
    },
    getListOfUsers() {
      ApiCalls.httpCallDetails(
        Api.MYPROFILE + this.$oidc.userProfile.unique_name,
        "get"
      ).then((data) => {
        this.myDetails = data.data;
        //this.roleDetailsItems = data.data.

        this.getProfileDetails = true;
        this.emailId = data.data.emailId;
        this.address = data.data.address;
        this.countryCode = data.data.countryCode;
        this.selectedTitle = data.data.title;

        this.contactno = data.data.contactNumber;
      });
    },

    onChangeListener(value) {
      this.showPopup = false;
      this.timeStamp = new Date().toISOString();
      if (value.files[0] && value.files[0].size < 10485760) {
        let reader = new FileReader();
        reader.readAsDataURL(value.files[0]);
        reader.onloadend = () => {
          let imageSet = [];
          imageSet.push(reader.result);
          let newImageSet = [];
          newImageSet.push(value.files[0]);
          let index = this.documentIndex++;
          let format = value.files[0].type.split("/")[1];
          let timeStamp = this.timeStamp;

          let fileUploadFormData = new FormData();
          if (format == "png" || format == "jpg" || format == "jpeg") {
            fileUploadFormData.append(
              "imgsrc",
              newImageSet[0],
              index + "_" + timeStamp + "." + format
            );
            this.showLoader = true;

            ApiCalls.httpCallDetails(
              Api.CREATEDOCUMENT,
              "post",
              fileUploadFormData
            ).then((data) => {
              this.showLoader = false;
              if (data.message) {
                let url = data.message;
                data.format = url.substring(url.lastIndexOf(".") + 1);
                this.documentDetailsArr.push(data);
                this.myDetails.profilePicUrl = data.message;
                this.profilePicUrl = data.message;
              }
            });
          } else {
            this.errorMsg =
              "This format of image is not allowed. Acceptable formats are - png, jpg.";
            this.headText = "Message";
            this.showPopup = true;
          }
        };
      } else {
        this.errorMsg = "File size is larger than 10MB.";
        this.headText = "Message";
        this.showPopup = true;
        console.warn("file is larger than 10MB");
      }
    },
    saveDetails() {
      this.showPopup = false;
      let cnt = 0;
      let countF = false;
      let count = 0;
      let contacts = [];
      let country = [];
      contacts = this.contactno;
      country = this.countryCode;
      let contField = false;
      let flag1 = false;
      let flag2 = false;
      if (this.contactno.length == 10) {
        for (const element of contacts) {
          if (contacts[0] == "0" && contacts[1] == "0") {
            contField = true;
          }
          if (element == "0") {
            cnt++;
          }
        }
        if (cnt > 6 || contField === true || count == contacts.length) {
          flag1 = false;
          this.errorMsg = "It is not a valid contact number.";
          this.headText = "";
          this.showPopup = true;
        } else {
          flag1 = true;
        }
      } else {
        this.errorMsg = "Length of contact number should be 10.";
        this.headText = "";
        this.showPopup = true;
      }
      if (this.countryCode.length) {
        for (const element of country) {
          if (element == "0") {
            count++;
          }
        }
        if (count > 4 || countF === true || count == country.length) {
          flag2 = false;
          this.errorMsg = "It is not a valid country code.";
          this.headText = "";
          this.showPopup = true;
        } else {
          flag2 = true;
        }
      }
      if (flag1 === true && flag2 === true && this.contactno.length == 10) {
        this.showLoader = true;
        let requestPayload = {
          userId: this.myDetails.userId,
          countryCode: this.countryCode ? this.countryCode : null,
          contact: this.contactno ? this.contactno : null,
          address: null,
          userProfilePicture: this.myDetails.profilePicUrl
            ? this.myDetails.profilePicUrl
            : null,
          title: this.selectedTitle,
          //  this.myDetails.profilePicUrl
        };

        ApiCalls.httpCallDetails(Api.EDITUSER, "put", requestPayload).then(
          (data) => {
            this.showLoader = false;
            this.$router.go(-1);
          }
        );
      }
    },
  },
};
</script>

<style scoped>
.role-details-sep {
  padding-right: 7px;
  border-right: 1px solid #d5d5d5;
}

.role-details {
  margin-right: 0.5rem;
}

.no-img-my {
  border-radius: 50% !important;
  border: 1px solid #d6d3d3;
  height: 8rem;
  width: 8rem;
  background: #f4f1de;
  display: flex;
  align-items: center;
  justify-content: center;
}

.discard-btn {
  color: #274ba7;
  background: transparent;
  border: transparent;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
  margin-right: 2rem;
}

.discard-btn .primar-btn:active {
  border-color: transparent !important;
  color: #274ba7 !important;
  background: transparent !important;
}

.submit-btn {
  height: 3.3rem;
  width: 7.166666666666666rem;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
  background-color: #274ba7;
}

.submit-btn:focus {
  background-color: #274ba7;
}

.add-action-footer-info {
  margin-top: 110px;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.top-padding {
  padding-top: 1rem;
}

.prp-info {
  padding-left: 2em;
  padding-right: 2em;
}

.padding-left {
  margin-left: 20px;
}

.action-info-block {
  background: #fff;
  display: flex;
}

.normal-text {
  color: #313131;
}

.plant {
  background: red;
  height: 30px;
  width: 50%;

  /* text-align:center; */
}

.asmt-bt {
  border-color: #869ed8 !important;
  background: #eff3ff 0% 0% no-repeat padding-box !important;
  color: #274ba7 !important;
}

.asmt-bt:hover {
  border-color: #869ed8 !important;
  color: #274ba7 !important;
  background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.ellipse-text {
  vertical-align: middle;
  font-family: "BarlowB", sans-serif;
  font-size: 1.3rem;
  color: #ffffff;
  line-height: 1.6666666666666667rem;
}

.principle-name-label {
  margin-top: 5px;
  vertical-align: middle;
  font-size: 1.33rem;
  font-family: "BarlowM", sans-serif;
  line-height: 1.6666666666666667rem;
}

.btn-action {
  background-color: #274ba7;
  font-family: "BarlowM", sans-serif;
  line-height: 1.6666666666666667rem;
  font-size: 1rem;
}

.no-data {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  height: 8rem;
  justify-content: space-evenly;
}

.ellipse {
  border-radius: 100%;
  width: 100px;
  height: 100px;
  background: #fff 0% 0% no-repeat padding-box;

  /* padding: 3px; */
}

.main-con {
  background: #fff;
  margin-top: 1em;
  border-radius: 6px;
  padding-bottom: 0px !important;
}

.mat-info-con {
  /* padding: 2rem; */
  display: flex;
}

.plant-dd {
  width: 30%;
}

.plant-select {
  height: 54px;
  border-color: #313131;
}

.profName {
  margin-top: 4.5rem;
  font-family: "BarlowSb", sans-serif;
  font-size: 1.3333rem;
  line-height: 1.3333rem;
  padding-top: 7px;
  padding-bottom: 2px;
  color: #313131;
}

.profDesignation {
  font-family: "BarlowR", sans-serif;
  font-size: 1.16rem;
  line-height: 1.4166666666666667rem;
  /* padding-top:7px; */
  padding-bottom: 2px;
  color: #313131;
}

.PEC {
  font-family: "BarlowB", sans-serif;
  font-size: 1.16rem;
  line-height: 1.4166666666666667rem;
  /* padding-top:7px; */
  padding-bottom: 2px;
  color: #313131;
  /* font-weight:700; */
}

.Prof {
  font-family: "BarlowM", sans-serif;
  font-size: 1.16rem;
  line-height: 1.4166666666666667rem;
  /* padding-top:7px; */
  padding-bottom: 2px;
  color: #313131;
}

.plant-card-info2 {
  width: 70%;
  padding: 3rem;
  /* padding-bottom:1rem; */
}

.plant-card-info {
  background: #fffdf2;
  padding-top: 30px;
  padding-bottom: 15px;
  text-align: center;
  color: #414141;
  border-radius: 7px;
  width: 30%;
  display: block;
}

.mm-menu {
  margin-right: 6rem;
}

.level-con {
  padding-left: 15px;
  padding-right: 15px;
}

.level-row {
  text-align: center;
  background: #eff3ff;
  border: 1px solid #ebecef;
  border-radius: 5px 5px 0px 0px;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 10px;
}

.level-status {
  color: #454545;
}

.level-date {
  opacity: 0.7;
}

.modify-date {
  position: absolute;
  top: 167px;
  right: 50px;
}

.dropdown-custome .dropdown-menu {
  min-width: 2rem;
}

.mat-stats-con {
  display: flex;
  padding-left: 2rem;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  align-items: center;
}

.mat-status-con {
  margin-left: 2rem;
  display: flex;
  align-items: center;
}

.last-ast-date {
  padding-top: 0.5rem;
  color: #5a5a5a;
}

.ast-action {
  display: flex;
  justify-content: center;
}

.take-ast-btn {
  margin-right: 1rem;
}

.pro-img-border {
  border: 1px solid #d6d3d3;
}

.mr-top-2 {
  margin-top: 2rem;
}

.input-fields {
  height: 4rem;
  padding-left: 1.3rem;
}

.action-details:focus-visible {
  /* border: 1px solid red !important;
   */
  background-color: #fff;
  border-color: #313131;
  outline: 0;
}

.contact {
  border: 1px solid #313131;
}

@media only screen and (max-width: 575px) {
  .mat-stats-con {
    display: block !important;
  }

  .mat-status-con {
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 767px) {
  .mat-info-con[data-v-dcabda2e] {
    display: block !important;
  }

  .plant-dd[data-v-dcabda2e] {
    width: 100% !important;
  }

  .plant-card[data-v-dcabda2e] {
    width: 100% !important;
    margin-left: 0em !important;
    margin-top: 1em;
  }

  .modify-date {
    top: 437px !important;
  }
}

.text-al-cen {
  display: flex;
  flex-direction: column;
  align-items: center;
}

textarea {
  resize: none;
  width: 100%;
  padding-top: 1rem;
}

.email-fd[readonly] {
  background-color: #f9f9f9 !important;
  opacity: 1;
}

.discard-btn:hover {
  color: #274ba7 !important;
  background: transparent !important;
  border: transparent !important;
}

.pro-con {
  position: relative;
  display: flex;
  justify-content: center;
}

.upload-btn {
  position: absolute;
  top: 1rem;
  width: 9rem;
}

.remove-btn {
  position: absolute;
  top: 1rem;
  width: 7rem;
}

.upload-file {
  position: absolute;
  top: 1rem;
  width: 10rem;
  opacity: 0;
}

.no-img {
  border-radius: 50% !important;
  border: 1px solid #d6d3d3;
  height: 8rem;
  width: 8rem;
  background: #f4f1de;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-sh {
  font-size: 2rem;
  font-family: "BarlowL", sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>

