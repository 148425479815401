<template>
  <b-modal id="messagePopup" v-model="shows" ref="messagePopup" :no-close-on-backdrop="true">
    <template #modal-header="{ close }">
      <!-- Emulate built in modal header close button action -->
      <p class="pop-up-title">{{ headText }}</p>
      <b-button size="sm" class="close-modal" @click="close()"> x </b-button>
    </template>
    <template #default="{}">
      <input type="hidden" :value="requestRaised" />
      <div class="row col-12 page-content-regular">
        <p>
          <span class="page-heading-tab">
            {{ message }}
          </span>
        </p>
      </div>
    </template>

    <template #modal-footer="{}">
      <b-button @click="proceed()" pill variant="outline-secondary" v-if="routToBack"
        class="page-heading-tab btn start-course-bt ed-st-btn btn-secondary rounded-pill"
        style="border-color: #274ba7; background-color: #274ba7; color: #fff; margin-top: 0.5em; width: 8.333333333333334rem; height: 2.9166666666666665rem">
        OK
      </b-button>
      <b-button @click="proceed()" v-else pill variant="outline-secondary"
        class="page-heading-tab btn start-course-bt ed-st-btn btn-secondary rounded-pill"
        style="border-color: #274ba7; background-color: #274ba7; color: #fff; margin-top: 0.5em; width: 8.333333333333334rem; height: 2.9166666666666665rem">OK</b-button>
    </template>
  </b-modal>
</template>
<script>
export default {
  name: "MessagePopup",
  props: {
    headText: String,
    message: String,
    show: Boolean,
    routToBack: Boolean,
    reqRaise: String
  },
  data() {
    return {
      request: "",
      shows: false
    };
  },
  computed: {
    requestRaised() {
      this.request = this.reqRaise;
    }
  },
  watch: {
    show: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(newVal, oldVal) {
        this.shows = newVal;
      }
    }
  },
  mounted() {
    this.shows = this.show;
  },
  methods: {
    proceed() {
      if (this.routToBack) {
        if (this.request == "close") {
          this.$router.go(-1);
        } else {
          this.$refs.messagePopup.hide();
          this.$router.go(-1);
        }
      }
      else
        this.$refs.messagePopup.hide();
    }
  }
};
</script>
<style scoped>
.pop-up-title {
  color: #234372;
  font-size: 1.3333333333333333rem;
  font-family: "BarlowSb", sans-serif;
}

.uploaded-items {
  list-style: none;
  padding: 0;
}

.uploaded-items-li {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.close-modal {
  background-color: transparent;
  border-color: transparent;
  color: #a3a3a3;
  font-size: 1.5rem;
  line-height: 0;
  padding: 0;
}

.edit-btn {
  height: 2.2rem;
  padding-top: 0.25rem !important;
  margin-left: 0.5rem;
}

.edit-btn:focus {
  background-color: #274ba7;
}
</style>
